@import "../../../global.scss";

.dashboard_container {
  width: 100%;
}

.projectlistTableContainer {
  box-shadow: 0px 2px 4px rgb(111, 110, 110);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 90%;
  margin: 0 auto;
}

.tstylee {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  font-size: small;
}

.tstylee td,
.tstylee thead {
  padding: 10px;
}

.tstylee tbody td {
  max-width: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  a {
    text-decoration: none;
    color: blue;
  }
}

.tstylee tbody tr:nth-child(even) {
  background-color: #ddd;
}

.tstylee thead td {
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
  background-color: $primary-darkblue-color;
  color: white;
}

.red {
  width: 20px;
  height: 20px;
  background-color: red;
  margin: 0 auto;
  border-radius: 50%;
}
.green {
  width: 20px;
  height: 20px;
  background-color: green;
  margin: 0 auto;
  border-radius: 50%;
}
.amber {
  width: 20px;
  height: 20px;
  background-color: $primary-yellow-color;
  margin: 0 auto;
  border-radius: 50%;
}
.nodata{
  vertical-align: middle;
  font-size: 24px;
  font-weight: bold;
}

.newAssessment {
  display: flex;
  justify-content: flex-end;
  button {
    border: none;
    outline: none;
    background-color: #074173;
    color: white;
    padding: 0.5rem 3rem;
    border-radius: 5px;
    margin: 0.5rem;
    cursor: pointer;
  }
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  color: red;
}

.searchInputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.inputContainer {
  position: relative;
  width: 20%;

  input {
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border-radius: 3px;
    border: 1px solid black;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
  }

  i {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
